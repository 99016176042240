import React, { useState } from "react"
import Page, { SECTION, HEADING, CONTENT, CONTENT_LI, LABEL } from '../components/Page';
import validate from 'validate.js';

const GATEWAY_URL = 'https://kz2rbvjj10.execute-api.us-east-1.amazonaws.com/Production';

const constraints = {
  name: {
    presence: {
      allowEmpty: false,
    }
  },
  email: {
    presence: true,
    email: true,
  },
  phone: {
    presence: true,
    length: {
      minimum: 10
    }
  },
  message: {
    presence: true,
    length: {
      minimum: 20
    }
  },
};

const Contact = () => {
  const [ formErrors, setFormErrors ] = useState(false);
  const [ formLoading, setFormLoading ] = useState(false);
  const [ submitError, setSubmitError ] = useState(false);
  const [ submitSuccess, setSubmitSuccess ] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    setSubmitError(false);
    setSubmitSuccess(false);
  
    const form = document.getElementById('ContactForm');
  
    const data = {
      name: form['name'].value,
      email: form['email'].value,
      phone: form['phone'].value,
      message: form['message'].value,
    };
  
    const errors = validate(data, constraints);
    if (errors) {
      setFormErrors(errors);

      return false;
    }

    setFormErrors(false);
    setFormLoading(true);
  
    try {
      fetch(GATEWAY_URL, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify(data),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });

      setSubmitSuccess(true);
    } catch (error) {
      setSubmitError(true);
      setFormLoading(false);
    }
  };

  return (
    <Page>
      <SECTION style={{ marginBottom: '50px' }}>
        <HEADING>Want to work with me?</HEADING>

        <CONTENT>
          Before you send a message, please note the following:
        </CONTENT>

        <ul>
          <CONTENT_LI>I do not accept work for equity or pro-bono</CONTENT_LI>
          <CONTENT_LI>I am not looking for partnerships</CONTENT_LI>
          <CONTENT_LI>My <a href="/privacy">Privacy Policy</a> describes how your data is stored and used. You will not receive spam.</CONTENT_LI>
        </ul>

        <hr />

        {!submitSuccess && (
          <form onSubmit={onSubmit} id="ContactForm">

            <div className="form-group">
              <LABEL htmlFor="name">Your name:</LABEL>
              <input
                type="text"
                className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}
                id="name"
                placeholder="Your name..."
              />
              <span className="invalid-feedback">{formErrors.name && formErrors.name[0]}</span>
            </div>

            <div className="form-group">
              <LABEL htmlFor="email">Your email:</LABEL>
              <input
                type="email"
                className={`form-control ${formErrors.email ? 'is-invalid' : ''}`}
                id="email"
                placeholder="you@email.com"
              />
              <span className="invalid-feedback">{formErrors.email}</span>
            </div>

            <div className="form-group">
              <LABEL htmlFor="phone">Your phone number:</LABEL>
              <input
                type="text"
                className={`form-control ${formErrors.phone ? 'is-invalid' : ''}`}
                id="phone"
                placeholder="(123) 456 - 7890"
              />
              <span className="invalid-feedback">{formErrors.phone}</span>
            </div>

            <div className="form-group">
              <LABEL htmlFor="message">Your message:</LABEL>
              <textarea
                className={`form-control ${formErrors.message ? 'is-invalid' : ''}`}
                rows={3}
                id="message"
              ></textarea>
              <span className="invalid-feedback">{formErrors.message}</span>
            </div>

            <button
              type="submit"
              className="btn btn-primary btn-lg"
              disabled={formLoading}
            >Submit</button>

            {submitError && (
              <div className="alert alert-danger" role="alert">
                Oops! A problem has occurred!
              </div>
            )}
          </form>
        )}

        {submitSuccess && (
          <div className="alert alert-success" role="alert">
            Thank you for contacting us! We'll be in touch soon!
          </div>
        )}
      </SECTION>
    </Page>
  );
};

export default Contact;
